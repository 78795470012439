<template>
  <div class='HonorDetail'>
    <!-- //最外层背景颜色 -->
    <div class="mainB">
      <!-- 头部 -->
      <div class="toBackBox">
        <span class="toGo">返回</span>
        <div class="btnBox">
          <span>不通过</span>
          <span>通过</span>
        </div>
      </div>
      <h4>{{honorData.name}}</h4>
      <p class="info">
        <span>荣誉类型：{{honorData.certificat_type_name}}</span>
        <span>获奖人：{{honorData.username_id_realname}}</span>
        <span>提交人：{{honorData.user_id_realname}}</span>
        <span>发起时间：{{honorData.update_time}}</span>
      </p>
      <div class="hs">
        {{honorData.synopsis}}
        <!-- 荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介 荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介荣誉简介 -->
      </div>
      <!-- //荣誉图片 -->
      <div class="ryPhoto">
        <img :src="honorData.resource_url" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      honorData: '',
    }
  },
  created() {
    //初始化
    this.init();
  },
  computed: {
  },
  methods: {
    init() {
      //获取详情
      this.getDetail();
    },
    getDetail() {
      let obj = {
        id: this.$route.query.id,
        teaching_studio_id: localStorage.getItem('studioId')
      }
      this.$axios.get("TeachingStudioCertificate/get_info", {
        params: obj
      }).then((res) => {
        this.honorData = res.data.data;
        console.log(res, "荣誉证书详情");
      })

    }
  },
}
</script>
<style lang="less" scoped>
.HonorDetail {
  width: 100%;
  background: #f7f7f7;
  padding: 10px 0;
  .mainB {
    padding-top: 30px;
    width: 1400px;
    background: #fff;
    margin: 0 auto;
    .toBackBox {
      display: flex;
      justify-content: space-between;
      padding: 46px 60px;
      .toGo {
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;

        position: relative;
        &::before {
          content: "<";
          width: 20px;
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          position: absolute;
          left: -18px;
        }
      }
      .btnBox {
        span {
          height: 34px;
          background: #f7f7f7;
          border: 1px solid #ececec;
          border-radius: 4px;
          padding: 5px 10px;
          margin-right: 20px;
        }
        span:nth-of-type(2) {
          background: #ff8201;
          border: 1px solid #ff8201;
          color: #fff;
        }
      }
    }
    h4 {
      text-align: center;
      font-size: 24px;
      font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
      font-weight: 700;
      color: #333333;
    }
    .info {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      span {
        margin-left: 30px;
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        color: #666666;
      }
    }
    .hs {
      width: 986px;
      // height: 86px;
      // background: #f7f7f7;
      padding: 25px;
      margin: 0 auto;
      margin-top: 37px;
      margin-bottom: 18px;
    }
    .ryPhoto {
      width: 986px;
      height: 656px;
      margin: 0 auto;
      img {
        width: 986px;
        height: 656px;
      }
    }
  }
}
</style>
